<template>
  <b-row id="all-time-dropdown" class="d-flex flex-row justify-content-center">
    <b-col>
      <b-card>
        <h1>Select Skill For Questions</h1>
        <div class="w-25">
          <b-form-group>
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="skill"
              :options="options"
              :reduce="(options) => options"
              class="select-size-sm"
              @input="getSkillId()"
              placeholder="Select Skill"
            />
          </b-form-group>
          <hr />
        </div>
      </b-card>
      <draggable
        ref="form"
        v-model="list"
        class="list-group list-group-flush cursor-move mx-1"
        tag="ul"
      >
        <b-row
          v-for="(question, index) in question_form.questions"
          :key="index"
          ref="row"
          class="my-1"
        >
          <b-col cols="12" lg="11" class="rounded-lg shadow-lg">
            <b-row>
              <b-col cols="12">
                <b-row class="p-2">
                  <b-col>
                    <b-row
                      class="d-flex flex-row justify-content-between align-items-center"
                    >
                      <b-col cols="12">
                        <b-form-group
                          label="Write Question"
                          :label-for="'question' + index"
                        >
                          <b-form-textarea
                            v-model="question.name"
                            :id="'question' + index"
                            size="lg"
                            placeholder="Write Question"
                            class="form-control"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Reference Image (Optional)"
                          :label-for="'image' + index"
                        >
                          <input
                            type="file"
                            class="form-control"
                            @change="onFileChange($event, index)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="(option, index) in question.option"
                      :key="index"
                      class="d-flex flex-row justify-content-between align-items-center border m-1"
                    >
                      <b-col cols="8">
                        <b-form-group
                          :label="`Option ${index + 1}`"
                          :label-for="`Option ${index + 1}`"
                        >
                          <b-form-input
                            v-model="option.option"
                            :id="`Option ${index + 1}`"
                            size="lg"
                            :placeholder="`Option ${index + 1}`"
                            class="form-control"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="8">
                        <div class="row">
                          <div class="col">
                            <b-form-group id="input-group-2">
                              <b-form-radio
                                v-if="question.type === 2"
                                v-model="option.correct"
                                :aria-describedby="ariaDescribedby"
                                name="some-radios"
                                value="1"
                              >
                                isCorrect
                              </b-form-radio>
                              <b-form-checkbox
                                v-else
                                v-model="option.correct"
                                :aria-describedby="ariaDescribedby"
                                name="some-radios"
                                value="1"
                              >
                                isCorrect
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                          <div class="col">
                            <button
                              class="btn btn-danger btn-sm ml-auto d-block text-end shadow-lg"
                              @click.prevent="remove(index, question)"
                              v-if="question.option.length > 1"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" class="py-1">
                    <button class="btn shadow-lg" @click="addMore(question)">
                      <img
                        src="@/assets/images/icons/add-icon.png"
                        alt="add"
                        class="mr-1"
                      />Add Option
                    </button>
                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col cols="2">
                <b-form-group class="py-2">
                  <label for="">Option Type</label>
                  <v-select
                    v-model="question.option_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="option_types"
                    label="name"
                    class="select-size-sm"
                    model-value="id"
                    placeholder="Select Option Type"
                    @input="getType(question)"
                  />
                </b-form-group>
                <b-form-group class="py-2" v-if="question.image">
                  <label for="">Selected Image</label>
                  <img :src="question.image" alt="image" class="img-fluid" />
                </b-form-group>
              </b-col> -->
            </b-row>
          </b-col>
          <b-col cols="12" lg="1" class="px-2">
            <b-card
              class="d-flex flex-column justify-content-start align-items-center rounded-lg shadow-lg bg-white p-2"
            >
              <button class="btn" @click="addNewItemInItemForm">
                <img src="@/assets/images/icons/add-icon.png" alt="add" />
              </button>
              <button class="btn" @click="CopyQuestion(question, index)">
                <img src="@/assets/images/icons/copy.png" alt="copy" />
              </button>
              <button class="btn">
                <img src="@/assets/images/icons/move.png" alt="move" />
              </button>
              <button
                class="btn"
                @click="removeItem(index)"
                v-if="question_form.questions.length > 1"
              >
                <img src="@/assets/images/icons/trash.png" alt="trash" />
              </button>
            </b-card>
          </b-col>
        </b-row>
      </draggable>
      <b-row>
        <b-col class="d-flex justify-content-end align-items-center mr-5">
          <!-- <b-button class="preview rounded-circle p-1">
            <img
              src="@/assets/images/icons/eye.png"
              alt="eye"
              height="15"
              width="15"
            />
          </b-button> -->
          <b-button
            @click="createEmployeeSkillQuestion()"
            class="mx-1 send-btn px-3 py-1 mr-5"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BListGroupItem,
  BModal,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import { toast } from "@/utils/toast";

export default {
  components: {
    draggable,
    BListGroupItem,
    BAvatar,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BFormRadio,
    BModal,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      question_form: {
        skill_id: 0,
        questions: [
          {
            name: "",
            option_type: {
              name: "Single Choice",
              value: 2,
            },
            type: 2,
            image: null,
            option: [
              {
                option: "",
                correct: 0,
              },
            ],
          },
        ],
      },
      ariaDescribedby: "",
      selected: null,
      options: [],
      list: [],
      option_types: [
        {
          name: "Single Choice",
          value: 2,
        },
        {
          name: "Multiple Choice",
          value: 1,
        },
      ],
    };
  },
  computed: {
    validation() {
      return this.serialNumber.length >= 1;
    },
  },
  mounted() {
    this.getSkill();
  },
  methods: {
    onFileChange(e, index) {
      let file = e.target.files[0];
      // base64
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.question_form.questions[index].image = reader.result;
      };
    },
    getType(question) {
      question.type = question.option_type.value;
    },
    addNewItemInItemForm() {
      this.question_form.questions.push({
        name: "",
        option_type: {
          name: "Single Choice",
          value: 2,
        },
        type: 2,
        image: null,
        option: [
          {
            option: "",
            correct: 0,
          },
        ],
      });
    },
    CopyQuestion(question, index) {
      // add new item in item form exacly same as selected item after selected item
      this.question_form.questions.splice(index + 1, 0, question);
    },
    removeItem(index) {
      if (this.question_form.questions.length > 1) {
        this.question_form.questions.splice(index, 1);
      }
    },
    getSkill() {
      this.$store
        .dispatch("employee/getSkills")
        .then((res) => {
          if (res.status_code === 200) {
            this.options = res.data;
          } else {
            this.errorMsg = "couldn't inserted";
          }
        })
        .catch((err) => {
          this.errorMsg = "please check code";
        });
    },
    createEmployeeSkillQuestion() {
      if (this.question_form.skill_id == 0) {
        toast("Error", "AlertTriangleIcon", "error", "Please select skill");
      } else {
        this.$store
          .dispatch("employee/createEmployeeSkillQuestion", this.question_form)
          .then((res) => {
            if (res.status_code === 200) {
              toast(
                "Successfully Created",
                "CheckCircleIcon",
                "success",
                "Question Created Successfully"
              );
            } else {
              toast("Error", "AlertTriangleIcon", "error", "Couldn't Created");
            }
          })
          .catch((err) => {
            toast("Error", "AlertTriangleIcon", "error", "Couldn't Created");
          });
      }
    },
    addMore(question) {
      question.option.push({
        option: "",
        correct: 0,
      });
    },
    remove(index, question) {
      if (question.option.length > 1) {
        question.option.splice(index, 1);
      }
    },
    getSkillId() {
      this.question_form.skill_id = this.selected.id;
    },
  },
};
</script>

<style scoped>
.list-group-item {
  transition: all 1s;
}

.send-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.preview {
  border-color: transparent !important;
  background: linear-gradient(315deg, white 100%, white 100%, white 100%);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
